import {
  and,
  collection,
  getCountFromServer,
  getDocs,
  or,
  orderBy,
  query,
  where
} from "firebase/firestore";
import { Database } from "../../firebase";
// import { ScreeningClient } from "../models/screening-client";
import { ClientUser } from "../models/client-user";
import { Outreach, OutreachPurpose } from "@oben-core-web/models/outreach";
import { Appointment } from "@oben-core-web/models/appointment";
import { AppointmentStatus } from "@oben-core-web/models/appointment-status-change";
import { DateTime } from "luxon";

export class ProgramManagerQueryService {
  static async getEnrollmentCounts({
    placeBasedCareProvId
  }: {
    placeBasedCareProvId: string;
  }): Promise<number> {
    if (!placeBasedCareProvId) {
      throw new Error("Missing required parameters");
    }
    try {
      // const screeningClientQuery = query(
      //   collection(Database, "screeningClients"),
      //   where("placeBasedCareProvId", "==", placeBasedCareProvId)
      // );
      const clientUserQuery = query(
        collection(Database, "clients"),
        and(
          where("placeBasedCareProvId", "==", placeBasedCareProvId),
          or(
            where("enrollmentDate", "==", ""),
            where("enrollmentDate", "==", null)
          )
        )
      );
      // const screeningClientCounts = await getCountFromServer(
      //   screeningClientQuery
      // );
      const clientUserCounts = await getCountFromServer(clientUserQuery);
      return clientUserCounts.data().count;
      // return screeningClientCounts.data().count + clientUserCounts.data().count;
    } catch (e) {
      console.log("Error getting enrollment counts", e);
      throw e;
    }
  }

  static async getEnrollmentPatients({
    placeBasedCareProvId
  }: {
    placeBasedCareProvId: string;
  }): Promise<{ client: ClientUser; enrollmentOutreach: Outreach | null }[]> {
    if (!placeBasedCareProvId) {
      throw new Error("Missing required parameters");
    }
    try {
      // const screeningClientQuery = query(
      //   collection(Database, "screeningClients"),
      //   where("placeBasedCareProvId", "==", placeBasedCareProvId)
      // );
      const clientUserQuery = query(
        collection(Database, "clients"),
        and(
          where("placeBasedCareProvId", "==", placeBasedCareProvId),
          or(
            where("enrollmentDate", "==", ""),
            where("enrollmentDate", "==", null)
          )
        )
      );
      // const screeningClientDocs = await getDocs(screeningClientQuery);
      const clientUserDocs = await getDocs(clientUserQuery);

      if (clientUserDocs.empty) {
        return [];
      }

      // const screeningClients = screeningClientDocs.docs.map((doc) =>
      //   ScreeningClient.fromFirestore(doc)
      // );
      const clientUsers = clientUserDocs.docs.map((doc) =>
        ClientUser.fromFirestore(doc)
      );

      const enrollmentOutreachQuery = query(
        collection(Database, "outreaches"),
        where(
          "clientId",
          "in",
          clientUsers.map((u) => u.uid)
        ),
        where("purpose", "==", OutreachPurpose.NewEnrollment),
        orderBy("createdDate", "asc")
      );

      const enrollmentOutreachDocs = await getDocs(enrollmentOutreachQuery);
      const outreaches = enrollmentOutreachDocs.docs.map((doc) =>
        Outreach.fromFirestore(doc)
      );

      const matchedClients = clientUsers.map((client) => {
        const matchingOutreach = outreaches.find(
          (o) => o.clientId === client.uid
        );
        return {
          client,
          enrollmentOutreach: matchingOutreach ?? null
        };
      });

      return matchedClients.sort((a, b) =>
        a.client.registrationDate && b.client.registrationDate
          ? a.client.registrationDate < b.client.registrationDate
            ? -1
            : 1
          : 1
      );
      // return [
      //   ...clientUsers
      //   // ...screeningClients
      // ].sort((a, b) =>
      //   a.registrationDate && b.registrationDate
      //     ? a.registrationDate < b.registrationDate
      //       ? -1
      //       : 1
      //     : 1
      // );
    } catch (e) {
      console.log("Error getting enrollment counts", e);
      throw e;
    }
  }

  static async getNeedToScheduleCount({
    placeBasedCareProvId
  }: {
    placeBasedCareProvId: string;
  }): Promise<number> {
    if (!placeBasedCareProvId) {
      throw new Error("Missing required parameters");
    }
    try {
      const internalTaskQuery = query(
        collection(Database, "internalTasks"),
        where("placeBasedCareProvId", "==", placeBasedCareProvId),
        where("internalTaskType", "==", "ScheduleAppointment"),
        where("completionDate", "==", null)
      );
      const internalTaskCount = await getCountFromServer(internalTaskQuery);

      return internalTaskCount.data().count;
    } catch (e) {
      console.log("Error getting scheduling counts", e);
      throw e;
    }
  }

  static async getNeedToConfirmCount({
    placeBasedCareProvId,
    startOfRange = DateTime.now().startOf("day").toJSDate(),
    endOfRange = DateTime.now().startOf("day").plus({ days: 30 }).toJSDate()
  }: {
    placeBasedCareProvId: string;
    startOfRange?: Date;
    endOfRange?: Date;
  }): Promise<number> {
    if (!placeBasedCareProvId) {
      throw new Error("Missing required parameters");
    }
    try {
      const webUserQuery = query(
        collection(Database, "webUsers"),
        where("placeBasedCareProvId", "==", placeBasedCareProvId)
      );
      const webUsers = await getDocs(webUserQuery);
      if (webUsers.empty) return 0;

      const queryFilters = [
        where(
          "pharmacistId",
          "in",
          webUsers.docs.map((u) => u.id)
        )
      ];

      if (startOfRange) {
        queryFilters.push(where("date", ">=", startOfRange.toISOString()));
      }
      if (endOfRange) {
        queryFilters.push(where("date", "<", endOfRange.toISOString()));
      }

      const appointmentQuery = query(
        collection(Database, "appointments"),
        ...queryFilters
      );

      const newAppts = (await getDocs(appointmentQuery)).docs
        .map((a) => Appointment.fromFirestore(a))
        .filter(
          (a) =>
            a.statusChanges[a.statusChanges.length - 1]?.status ===
            AppointmentStatus.New
        );

      return newAppts.length ?? 0;
    } catch (e) {
      console.log("Error getting appointment confirmation counts", e);
      throw e;
    }
  }
}
