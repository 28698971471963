import React from "react";
import {
  // Button,
  Center,
  Text,
  VStack
} from "@chakra-ui/react";
// import { useTourContext } from "@/hooks/useTourContext";
// import useTourSteps from "@/hooks/useTourSteps";
// import useProgramManagerQueries from "@/hooks/useProgramManagerQueries";
import useCurrentUser from "@/hooks/useCurrentUser";
import ProgramManagerDashboard from "../organisms/ProgramManagerDashboard";
import { UserType } from "@oben-core-web/constants/core-enums";
import ClientDashboardStatistics from "../organisms/ClientDashboardStatistics";
import UpcomingAppointments from "../molecules/UpcomingAppointmentsTable";

const Home = () => {
  const { currentUser } = useCurrentUser();
  // const { getBarbersByStripeStatus } = useProgramManagerQueries(
  //   currentUser?.placeBasedCareProvId ?? ""
  // );
  // const { getTourSteps } = useTourSteps();
  // const { startTour } = useTourContext();
  return (
    <VStack w={"full"} h={"full"} alignItems={"flex-start"}>
      <Text as={"h1"} fontSize={"xx-large"}>
        Welcome {currentUser?.name.first}!
      </Text>
      <Center
        h={"full"}
        w={"full"}
        // bg={"white"}
        rounded={"md"}
        flexDirection={"column"}
        justifyContent={"flex-start"}
      >
        {currentUser?.userType === UserType.ProgramManager && (
          <ProgramManagerDashboard />
        )}
        {(currentUser?.userType === UserType.Pharmacist ||
          currentUser?.userType === UserType.Physician) && (
          <>
            <ClientDashboardStatistics />
            <UpcomingAppointments h={"50%"} />
          </>
        )}
      </Center>
    </VStack>
  );
};

export default Home;
