import React, { useEffect, useState } from "react";
import {
  VStack,
  HStack,
  Avatar,
  Heading,
  Text,
  Card,
  SimpleGrid
} from "@chakra-ui/react";
import { ClientUser } from "@oben-core-web/models/client-user";
import { DateTime } from "luxon";
import PatientProfileForm from "./PatientProfileForm";
import { PrimaryCareProv } from "@oben-core-web/models/primary-care-prov";
import { BarberUser } from "@oben-core-web/models/barber-user";
import { Barbershop } from "@oben-core-web/models/barbershop";
import { Payer } from "@oben-core-web/models/payer";
import { BarberUserService } from "@oben-core-web/services/barber-user-service";
import { BarbershopService } from "@oben-core-web/services/barbershop-service";
import { PayerService } from "@oben-core-web/services/payer-service";
import { PrimaryCareProvService } from "@oben-core-web/services/primary-care-prov-service";
import { parsePhoneNumber } from "@/lib/parseUtils";

interface IPatientProfile {
  patient: ClientUser;
}

const PatientProfile = ({ patient }: IPatientProfile) => {
  const [relatedPatientData, setRelatedPatentData] = useState<{
    prefBarber: BarberUser | null;
    prefBarbershop: Barbershop | null;
    payer: Payer | null;
    primaryCareHome: PrimaryCareProv | null;
  }>({
    prefBarber: null,
    prefBarbershop: null,
    payer: null,
    primaryCareHome: null
  });

  useEffect(() => {
    const fetchRelatedData = async () => {
      const barberService = new BarberUserService();
      const barbershopService = new BarbershopService();
      const payerService = new PayerService();
      const primaryCareProvService = new PrimaryCareProvService();

      const [prefBarber, prefBarbershop, payer, primaryCareHome] =
        await Promise.allSettled([
          barberService.getBarberUser(patient.prefBarberid ?? ""),
          barbershopService.getBarbershop(patient.prefBarbershopId ?? ""),
          payerService.getPayer(patient.payerId ?? ""),
          primaryCareProvService.getPrimaryCareProv(
            patient.primaryCareProvId ?? ""
          )
        ]).then((results) =>
          results.map((r, i) => {
            if (r.status === "fulfilled") {
              switch (i) {
                case 0:
                  return r.value as BarberUser;
                case 1:
                  return r.value as Barbershop;
                case 2:
                  return r.value as Payer;
                case 3:
                  return r.value as PrimaryCareProv;
                default:
                  return null;
              }
            } else {
              return null;
            }
          })
        );
      // const prefBarber = await barberService.getBarberUser(
      //   patient.prefBarberid ?? ""
      // );
      // const prefBarbershop = await barbershopService.getBarbershop(
      //   patient.prefBarbershopId ?? ""
      // );
      // const payer = await payerService.getPayer(patient.payerId ?? "");
      // const primaryCareHome = await primaryCareProvService.getPrimaryCareProv(
      //   patient.primaryCareProvId ?? ""
      // );
      setRelatedPatentData({
        prefBarber: prefBarber as BarberUser | null,
        prefBarbershop: prefBarbershop as Barbershop | null,
        payer: payer as Payer | null,
        primaryCareHome: primaryCareHome as PrimaryCareProv | null
      });
    };
    fetchRelatedData();
  }, [
    patient.prefBarberid,
    patient.prefBarbershopId,
    patient.payerId,
    patient.primaryCareProvId
  ]);

  return (
    <HStack
      className='patient-profile'
      align='center'
      justify='space-between'
      spacing={4}
      minH={"18rem"}
      bg={"gray.50"}
      mb={4}
    >
      <Card w='25%' h={"18rem"}>
        <VStack align='center' justify='center' spacing={1} h={"full"}>
          <Avatar size='2xl' bg='blue.500' />
          <Heading as='h1' size='lg'>
            {patient.name?.display}
          </Heading>
          {patient.phoneNumber && (
            <Text>{parsePhoneNumber(patient.phoneNumber)}</Text>
          )}
          {patient.email && <Text>{patient.email}</Text>}
        </VStack>
      </Card>
      <Card w='75%' h={"18rem"} justifyContent={"center"} px={8} gap={4}>
        <PatientProfileForm clientId={patient.uid} />
        <SimpleGrid columns={3} rowGap={8}>
          <VStack align='start' spacing={1}>
            <Text fontSize='small'>Sex</Text>
            <Text as={"b"} casing={"capitalize"}>
              {patient.sex}
            </Text>
          </VStack>
          <VStack align='start' spacing={1}>
            <Text fontSize='small'>Ethnicity</Text>
            <Text as={"b"}>Black</Text>
            {/* <Text as={"b"}>{patient.ethnicities?.[0]}</Text> */}
          </VStack>
          <VStack align='start' spacing={1}>
            <Text fontSize='small'>Date of Birth</Text>
            {patient.dob && (
              <Text as={"b"}>
                {DateTime.fromJSDate(patient.dob).toUTC().toFormat("DD")}
              </Text>
            )}
          </VStack>
          <VStack align='start' spacing={1}>
            <Text fontSize='small'>Preferred Barber</Text>
            <Text as={"b"}>
              {relatedPatientData.prefBarber?.name.display ?? "None"}
            </Text>
          </VStack>
          {/* <VStack align='start' spacing={1}>
            <Text fontSize='small'>Physician</Text>
            <Text as={"b"}>Tem Woldeyesus</Text>
          </VStack> */}
          <VStack align='start' spacing={1}>
            <Text fontSize='small'>Preferred Barbershop</Text>
            <Text as={"b"}>
              {relatedPatientData.prefBarbershop?.businessName ?? "None"}
            </Text>
            {/* <Text as={"b"}>[barbershop name]</Text> */}
          </VStack>
          <VStack align='start' spacing={1}>
            <Text fontSize='small'>Last Active</Text>
            <Text as={"b"}>
              {patient.lastActivityDate
                ? DateTime.fromJSDate(patient.lastActivityDate).toFormat("DDD")
                : ""}
            </Text>
          </VStack>
          <VStack align='start' spacing={1}>
            <Text fontSize='small'>Last BP Reading</Text>
            <Text as={"b"}>
              {patient.currentBpReading
                ? patient.currentBpReading.systolic +
                  "/" +
                  patient.currentBpReading.diastolic
                : ""}
            </Text>
          </VStack>
          <VStack align='start' spacing={1}>
            <Text fontSize='small'>Primary Care Home</Text>
            <Text as={"b"}>
              {relatedPatientData.primaryCareHome?.businessName ?? "None"}
              {/* {primaryCareProvs?.find(
                (pcp) => pcp.id === patient.primaryCareProvId
              )?.businessName ?? "None"} */}
            </Text>
            {/* <Text as={"b"}>[physician name]</Text> */}
          </VStack>
          <VStack align='start' spacing={1}>
            <Text fontSize='small'>Insurance Provider</Text>
            <Text as={"b"}>
              {relatedPatientData.payer?.businessName ?? "None"}
              {/* {payers?.find((p) => p.id === patient.payerId)?.businessName ??
                "None"} */}
            </Text>
            {/* <Text as={"b"}>[barbershop name]</Text> */}
          </VStack>
        </SimpleGrid>
      </Card>
    </HStack>
  );
};

export default PatientProfile;
