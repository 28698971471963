import useAppointments from "@/hooks/useAppointments";
import useCurrentUser from "@/hooks/useCurrentUser";
import { DateTime } from "luxon";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { Appointment } from "@oben-core-web/models/appointment";
import { transformToLabel } from "@/lib/enumUtils";
import {
  Heading,
  Link as ChakraLink,
  IconButton,
  HStack,
  Tooltip,
  Box,
  BoxProps
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { MdCalendarMonth, MdPerson } from "react-icons/md";
import { startCase } from "lodash";

interface IUpcomingAppointments extends BoxProps {}
const UpcomingAppointments = (props: IUpcomingAppointments) => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { appointments } = useAppointments({
    pharmacistId: currentUser?.uid ?? "",
    start: DateTime.now().startOf("day").toJSDate(),
    end: DateTime.now().startOf("day").plus({ days: 30 }).toJSDate()
  });
  const rowData = appointments.map((appt) => {
    const latestStatusChange = getLatestStatusChange(appt);
    const statusLabel = latestStatusChange
      ? transformToLabel(latestStatusChange.status)
      : "Unknown";
    return {
      appointment: appt,
      date: DateTime.fromJSDate(appt.date!).toFormat("D"),
      time: DateTime.fromJSDate(appt.date!).toFormat("t"),
      client:
        appt.clientName.display ??
        appt.clientName.first + " " + appt.clientName.last,
      clientId: appt.clientId,
      barber: appt.barberName.display,
      barbershop: appt.barbershopName,
      status: statusLabel === "New" ? "Unconfirmed" : startCase(statusLabel)
    };
  });

  const columnDefs: ColDef[] = [
    { field: "date", headerName: "Date", flex: 0.4 },
    { field: "time", headerName: "Time", flex: 0.4 },
    {
      field: "client",
      headerName: "Patient",
      flex: 1,
      cellRenderer: (params: any) => (
        <ChakraLink as={Link} to={`/patient/${params.data.clientId}`}>
          {params.data.client}
        </ChakraLink>
      )
    },
    { field: "barber", headerName: "Barber", flex: 1 },
    { field: "barbershop", headerName: "Barbershop", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      valueFormatter: (row) => startCase(row.data.status)
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.4,
      cellRenderer: (params: any) => {
        return (
          <HStack>
            <Tooltip label={"Go to Appointment"}>
              <IconButton
                onClick={() => {
                  const { appointment } = params.data;
                  if (!appointment) return;
                  navigate("/appointments", {
                    state: {
                      appointment: {
                        ...appointment,
                        startDateTime: appointment.date,
                        endDateTime: DateTime.fromJSDate(appointment.date)
                          .plus({ minutes: appointment.length })
                          .toJSDate()
                      },
                      isOpen: true
                    },
                    replace: true
                  });
                }}
                variant={"ghost"}
                aria-label={`nav-to-appointment-${params.data.id}`}
                icon={<MdCalendarMonth size={24} />}
              />
            </Tooltip>
            <Tooltip label={"Go to Patient"}>
              <IconButton
                onClick={() => {
                  const { appointment } = params.data;
                  if (!appointment) return;
                  navigate(`/patient/${params.data.clientId}`);
                }}
                variant={"ghost"}
                aria-label={`nav-to-patient-${params.data.clientId}`}
                icon={<MdPerson size={24} />}
              />
            </Tooltip>
          </HStack>
        );
      }
    }
  ];
  return (
    <Box className='ag-theme-quartz' w={"full"} {...props}>
      <Heading fontSize={"lg"} my={2}>
        Upcoming Appointments
      </Heading>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs as any}
        rowSelection='single'
        // onRowSelected={handleRowSelected}
        // onRowDoubleClicked={(row) => row.data && navToPatient(row.data.id)}
        // autoSizeStrategy={{
        //   type: "fitProvidedWidth",
        //   width: columnDefs.reduce((a, c) => {
        //     return a + c.width;
        //   }, 0)
        // }}
      />
    </Box>
  );
};

export default UpcomingAppointments;

const getLatestStatusChange = (appointment: Appointment) => {
  const { statusChanges } = appointment;
  const sortedChanges = statusChanges.toSorted((a, b) => {
    return DateTime.fromJSDate(a.date) < DateTime.fromJSDate(b.date) ? -1 : 1;
  });
  return sortedChanges[0];
};
