import useAppointments from "@/hooks/useAppointments";
import { Card, Heading, HStack, Text } from "@chakra-ui/react";
import { UserType } from "@oben-core-web/constants/core-enums";
import { AppointmentStatus } from "@oben-core-web/models/appointment-status-change";
import { WebUserService } from "@oben-core-web/services/web-user-service";
import { groupBy } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  LuCircle,
  LuCheckCircle,
  LuCircleOff,
  LuCircleDashed,
  LuCalendar
} from "react-icons/lu";

const DashboardAppointmentScheduling = () => {
  const [webUserIds, setWebUserIds] = useState<string>();

  const thirtyDaysAgo = useMemo(() => {
    return DateTime.now()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .minus({ days: 30 })
      .toJSDate();
  }, []);
  const thirtyDaysFromNow = useMemo(
    () =>
      DateTime.now()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .plus({ days: 30 })
        .toJSDate(),
    []
  );
  // TODO: see todo in useAppointments
  const { appointments } = useAppointments({
    pharmacistId: webUserIds,
    start: thirtyDaysAgo,
    end: thirtyDaysFromNow
  });
  const apptsByStatus = groupBy(appointments, (apt) => {
    const { statusChanges } = apt;
    return statusChanges[statusChanges.length - 1]?.status;
  });
  const fetchWebUsers = useCallback(async () => {
    const webUserService = new WebUserService();
    return await webUserService.getAllWebUsers([
      UserType.Pharmacist,
      UserType.Physician
    ]);
  }, []);
  useEffect(() => {
    const init = async () => {
      const users = await fetchWebUsers();
      const webUserIds = users.map((wu) => wu.uid);
      setWebUserIds(webUserIds.join(","));
    };

    init();
  }, [fetchWebUsers]);

  return (
    <Card w={"60%"} p={5}>
      <Heading as={"h2"} mb={2} fontSize='lg' display={"flex"}>
        <LuCalendar /> &nbsp;Appointments and Scheduling
      </Heading>

      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuCircle />
          <Text fontWeight={"normal"}>Needs Confirmation</Text>
        </HStack>

        <Text>{apptsByStatus[AppointmentStatus.New]?.length ?? 0}</Text>
      </HStack>
      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuCheckCircle />
          <Text fontWeight={"normal"}>Confirmed Appointments</Text>
        </HStack>

        <Text>{apptsByStatus[AppointmentStatus.Confirmed]?.length ?? 0}</Text>
      </HStack>
      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuCircleOff />
          <Text fontWeight={"normal"}>Cancellations</Text>
        </HStack>

        <Text>{apptsByStatus[AppointmentStatus.Canceled]?.length ?? 0}</Text>
      </HStack>
      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuCircleDashed />
          <Text fontWeight={"normal"}>No Shows</Text>
        </HStack>

        <Text>{apptsByStatus[AppointmentStatus.NoShow]?.length ?? 0}</Text>
      </HStack>
    </Card>
  );
};

export default DashboardAppointmentScheduling;
