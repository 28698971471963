import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  runTransaction,
  where
} from "firebase/firestore";
import { CloudFunctions, Database } from "../../firebase";
import { Appointment } from "../models/appointment";
import { WebUser } from "../models/web-user";
import { BarberUser, StripeStatus } from "../models/barber-user";
import { BillableEvent, BillableEventType } from "../models/billable-event";
import { AppointmentStatus } from "../models/appointment-status-change";
import { DateTime } from "luxon";
import { BillableItem } from "../models/billable-item";
import { BillStatus, BillStatusChange } from "../models/bill-status-change";
import { httpsCallable } from "firebase/functions";
import { CloudFunctionResponse } from "@oben-core-web/models/cloud-function-response";
import { ClientType } from "@oben-core-web/constants/core-enums";

export class AppointmentTransactionService {
  static async createBillableFromApptStatus({
    appointmentId,
    placeBasedCareProvId,
    editorId,
    statusChange,
    approveImmediately = false
  }: {
    appointmentId: string;
    placeBasedCareProvId: string;
    editorId: string;
    statusChange: AppointmentStatus;
    approveImmediately: boolean;
  }) {
    if (!appointmentId || !statusChange || !editorId || !placeBasedCareProvId) {
      throw new Error("Missing required parameters");
    }
    try {
      await runTransaction(Database, async (transaction) => {
        // lookup required documents
        const editorRef = doc(Database, `webUsers/${editorId}`);
        const editorDoc = WebUser.fromFirestore(await getDoc(editorRef));
        const appointmentRef = doc(Database, `appointments/${appointmentId}`);
        const appointmentDoc = Appointment.fromFirestore(
          await getDoc(appointmentRef)
        );
        if (!appointmentDoc) {
          throw new Error("Could not fetch appointment");
        }
        if (!editorDoc) {
          throw new Error("Could not fetch editing web user");
        } else if (!editorDoc.placeBasedCareProvId) {
          throw new Error("Missing place based care provider id");
        }
        const barberDocRef = doc(
          Database,
          `barbers/${appointmentDoc.barberId}`
        );
        const barberDoc = BarberUser.fromFirestore(await getDoc(barberDocRef));
        if (!barberDoc) {
          throw new Error("Could not fetch barber");
        }

        // validate that this is the only billable event for this appointment
        const billableEventsCollectionRef = collection(
          Database,
          "billableEvents"
        );
        const existingBillableEventsQuery = query(
          billableEventsCollectionRef,
          where("appointmentId", "==", appointmentDoc.id),
          where("clientId", "==", appointmentDoc.clientId)
        );
        const billableEventsQueryResult = await getDocs(
          existingBillableEventsQuery
        );
        const hasExistingBillableEvents = billableEventsQueryResult.size > 0;
        if (hasExistingBillableEvents)
          throw new Error("This appointment already has a billable event");

        // set fee amount
        const { serviceFee, noShowFee, cancelFee, cancelWindow } = barberDoc;
        let billableItemFee: number = 0;
        let billableItemDescription: string;
        let submitCharge = false;
        switch (statusChange) {
          case AppointmentStatus.Canceled:
            if (appointmentDoc.date && cancelWindow && cancelFee) {
              const now = DateTime.fromJSDate(new Date());
              const cancelWindowStartDate = DateTime.fromJSDate(
                appointmentDoc.date
              ).minus({ days: cancelWindow });
              const appointmentStart = DateTime.fromJSDate(appointmentDoc.date);
              const appointmentEnd = DateTime.fromJSDate(
                appointmentDoc.date
              ).plus({ minutes: appointmentDoc.length });
              // must cancel before start of appointment for cancellation fee
              if (now >= cancelWindowStartDate && now < appointmentStart) {
                // appointment cancelled within cancellation window
                billableItemFee = cancelFee ?? 0;
                billableItemDescription =
                  "Appointment cancelled within cancellation window";
              } else if (now > appointmentStart && now < appointmentEnd) {
                // cancelled during appointment time, no-show fee
                billableItemFee = noShowFee ?? 0;
                billableItemDescription =
                  "Appointment cancelled during appointment window";
              } else {
                // if cancelling before window, pay no fee
                billableItemFee = 0;
                billableItemDescription =
                  "Appointment cancelled before cancellation window";
              }
            } else {
              throw new Error("Cancellation missing required params");
            }
            break;
          case AppointmentStatus.Completed:
            billableItemFee = serviceFee ?? 0;
            billableItemDescription = "Appointment started";
            submitCharge = true;
            break;
          case AppointmentStatus.NoShow:
            billableItemFee = noShowFee ?? 0;
            billableItemDescription = "Appointment no-show";
            break;
          case AppointmentStatus.New:
            billableItemFee = serviceFee ?? 0;
            billableItemDescription = "Appointment started";
            submitCharge = true;
            console.log("Unconfirmed appointment started");
            break;
          default:
            billableItemFee = 0;
            billableItemDescription = "";
            break;
        }
        // only create billableEvent/Item if there is a fee to attach -- TODO: confirm that this is correct
        if (billableItemFee === 0) {
          throw new Error("Cannot create billable item with value 0");
        }
        // create billableEvent
        const billableEventDocRef = doc(billableEventsCollectionRef);
        const billableEventData = new BillableEvent({
          id: billableEventDocRef.id,
          appointmentId,
          clientId: appointmentDoc.clientId,
          clientType: ClientType.ClientUser,
          date: new Date(),
          eventType: BillableEventType.RxConsult,
          placeBasedCareProvId: editorDoc.placeBasedCareProvId!
        });
        await transaction.set(billableEventDocRef, billableEventData.toJson());

        // create billableItem
        const billableItemCollectionRef = collection(
          Database,
          "billableEvents",
          billableEventDocRef.id,
          "billableItems"
        );
        const billableItemDoc = doc(billableItemCollectionRef);
        const statusChanges = [
          new BillStatusChange({
            status: BillStatus.New,
            date: new Date(),
            editorType: editorDoc.userType,
            editorId: editorDoc.uid,
            details: billableItemDescription
          })
        ];
        if (approveImmediately) {
          statusChanges.push(
            new BillStatusChange({
              status: BillStatus.ApprovedForSubmission,
              date: new Date(),
              editorType: editorDoc!.userType,
              editorId: editorDoc!.uid,
              details: `Approved by ${editorDoc!.userType} - ${
                editorDoc!.name.display
              }`
            })
          );
        }
        const billableItem = new BillableItem({
          modelId: billableItemDoc.id,
          billableEventId: billableEventDocRef.id,
          clientId: appointmentDoc.clientId,
          clientName: appointmentDoc.clientName,
          placeBasedCareMRN: "", //TODO: figure out what this is
          serviceDate: appointmentDoc.date!,
          billableItemBaseId: "", // TODO: figure out how to use this,
          placeBasedCareProvId,
          billingCode: "", //TODO: figure out where this comes from
          description: `Haircut for ${appointmentDoc.clientName.display} - ${billableItemDescription}`,
          amount: billableItemFee,
          barberId: appointmentDoc.barberId,
          payerId: "",
          payerPolicyNumber: "",
          billStatusChanges: statusChanges,
          stripePmtStatusChanges: []
        });
        await transaction.set(billableItemDoc, billableItem.toJson());

        // update appointment
        await transaction.update(appointmentRef, {
          billableEventId: billableEventDocRef.id
        });
        return {
          submitCharge,
          editorDoc,
          barberDoc,
          billableItemFee,
          billableItemDoc
        };
      }).then(
        async ({
          submitCharge,
          editorDoc,
          barberDoc,
          billableItemFee,
          billableItemDoc
        }) => {
          if (
            submitCharge &&
            barberDoc.stripeStatus === StripeStatus.TransfersEnabled
          ) {
            // call stripe api
            const createCustomerCharge = httpsCallable<
              {
                sender: string;
                receiver: string;
                amountCents: number;
                billableItemId: string;
                approverId: string;
              },
              CloudFunctionResponse
            >(CloudFunctions, "createCustomerCharge");
            const result = await createCustomerCharge({
              sender: editorDoc.placeBasedCareProvId!,
              receiver: barberDoc.uid,
              amountCents: billableItemFee,
              billableItemId: billableItemDoc.id,
              approverId: editorDoc!.uid
            });
            if (result.data.error) {
              throw result.data.error;
            } else {
              console.log(result.data.data);
            }
          }
        }
      );
    } catch (e) {
      console.log("Error creating billable from appointment status change", e);
      throw e;
    }
  }

  static async approveBillableItem({
    billableItemId,
    editorId,
    billStatusUpdate
  }: {
    billableItemId: string;
    editorId: string;
    billStatusUpdate: BillStatus;
  }) {
    if (!billStatusUpdate) throw new Error("No billStatusUpdate provided");

    const editorRef = doc(Database, `webUsers/${editorId}`);
    const editorDoc = WebUser.fromFirestore(await getDoc(editorRef));
    const billableItemCollectionRef = collectionGroup(
      Database,
      "billableItems"
    );
    const billableItemsQuery = await getDocs(
      query(
        billableItemCollectionRef,
        where("modelId", "==", billableItemId),
        limit(1)
      )
    );
    const billableItemDoc = BillableItem.fromFirestore(
      billableItemsQuery.docs[0]
    );

    if (!editorDoc || !billableItemDoc) {
      throw new Error("Could not fetch required documents");
    }
    try {
      await runTransaction(Database, async (transaction) => {
        const billableItemRef = doc(
          Database,
          "billableEvents",
          billableItemDoc.billableEventId,
          "billableItems",
          billableItemDoc.modelId
        );
        const billableItemStatusChange = new BillStatusChange({
          status: billStatusUpdate,
          date: new Date(),
          editorId: editorDoc.uid,
          editorType: editorDoc.userType,
          details: `${billStatusUpdate} update made to bill status`
        });
        await transaction.update(billableItemRef, {
          billStatusChanges: [
            ...billableItemDoc.billStatusChanges,
            billableItemStatusChange
          ].map((bsc) => bsc.toJson())
        });
        const createCustomerCharge = httpsCallable<
          {
            sender: string;
            receiver: string;
            amountCents: number;
            billableItemId: string;
            approverId: string;
          },
          CloudFunctionResponse
        >(CloudFunctions, "createCustomerCharge");
        const result = await createCustomerCharge({
          sender: editorDoc.placeBasedCareProvId!,
          receiver: billableItemDoc.barberId!,
          amountCents: billableItemDoc.amount,
          billableItemId: billableItemDoc.modelId,
          approverId: editorDoc!.uid
        });
        if (result.data.error) {
          throw result.data.error;
        } else {
          console.log(result.data.data);
        }
      });
    } catch (e) {
      console.log("Failed approve billable item", e);
      throw e;
    }
  }
}
