import useCurrentUser from "@/hooks/useCurrentUser";
import useInternalTasks from "@/hooks/useInternalSchedulingTasks";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { HStack, IconButton, Tooltip } from "@chakra-ui/react";
import { InternalTaskService } from "@oben-core-web/services/internal-task-service";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { BsClock } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

interface IPatientSchedulingTable {
  onTaskCompleted: () => void;
}
const PatientSchedulingTable = ({
  onTaskCompleted
}: IPatientSchedulingTable) => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { internalTasks, fetchInternalSchedulingTasks } = useInternalTasks(
    currentUser?.uid ?? ""
  );

  const columnHeaders: ColDef[] = [
    { field: "patientName", headerName: "Patient", flex: 1 },
    {
      field: "prefBarber.name.display",
      headerName: "Preferred Barber",
      flex: 1
    },
    {
      field: "prefBarbershop.businessName",
      headerName: "Preferred Barbershop",
      flex: 1
    },
    {
      field: "prefApptTimes",
      headerName: "Preferred Appt Times",
      cellStyle: { whiteSpace: "pre", height: "fit-content" },
      wrapText: true,
      autoHeight: true,
      flex: 2
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      cellRenderer: (row: any) => {
        return (
          <HStack alignItems={"center"} h={"full"}>
            <Tooltip label={"Schedule Appointment"}>
              <IconButton
                variant={"link"}
                aria-label={`scheduling-task-btn-${row.data.id}`}
                icon={<BsClock />}
                onClick={async () => {
                  navigate("/appointments", {
                    state: {
                      from: "/?page=scheduling",
                      clientId: row.data?.clientId ?? "NO ID",
                      clientName: row.data?.client.name,
                      prefBarber: row.data?.prefBarber,
                      prefBarbershop: row.data?.prefBarbershop,
                      internalTask: row.data.internalTask
                    },
                    replace: true
                  });
                }}
              />
            </Tooltip>
            <Tooltip label={"Mark Task as Completed"}>
              <IconButton
                variant={"link"}
                aria-label={`complete-task-btn-${row.data.id}`}
                icon={<CheckCircleIcon />}
                onClick={async () => {
                  const internalTaskService = new InternalTaskService();
                  row.data.internalTask.completionDate = new Date();
                  await internalTaskService
                    .updateTask(row.data.internalTask)
                    .then(() => {
                      fetchInternalSchedulingTasks(currentUser!.uid);
                      onTaskCompleted();
                    });
                }}
              />
            </Tooltip>
          </HStack>
        );
      }
    }
  ];

  return (
    <div className='ag-theme-quartz' style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        className='program-manager-patient-scheduling-table'
        rowData={internalTasks}
        columnDefs={columnHeaders as any}
        rowSelection='single'
        // onRowClicked={(row) =>
        //   navigate("/appointments", {
        //     state: {
        //       from: "/?page=scheduling",
        //       clientId: row.data?.clientId ?? "NO ID",
        //       clientName: row.data?.client.name,
        //       prefBarber: row.data?.prefBarber,
        //       prefBarbershop: row.data?.prefBarbershop
        //     },
        //     replace: true
        //   })
        // }
        // onRowDoubleClicked={(row) => row.data && navToPatient(row.data.id)}
        // autoSizeStrategy={{ type: "fitProvidedWidth", width: 1215 }}
      />
    </div>
  );
};

export default PatientSchedulingTable;
