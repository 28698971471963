import useBillableItems from "@/hooks/useBillableItems";
import useCurrentUser from "@/hooks/useCurrentUser";
import {
  Center,
  HStack,
  Text,
  VStack,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Spinner,
  useDisclosure,
  Tooltip,
  FormControl,
  FormLabel,
  Input
} from "@chakra-ui/react";
import { UserType } from "@oben-core-web/constants/core-enums";
import { DateTime } from "luxon";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { useMemo, useRef, useState } from "react";
import { BillableItemService } from "@oben-core-web/services/billable-item-service";
import { BillableItem } from "@oben-core-web/models/billable-item";
import {
  BillStatus,
  BillStatusChange
} from "@oben-core-web/models/bill-status-change";
import { AppointmentTransactionService } from "@oben-core-web/services/appointment-transaction-service";
import { StripeStatus } from "@oben-core-web/models/barber-user";
import useBarbers from "@/hooks/useBarbers";

// TODO: be able to filter by city

const PaymentsTable = () => {
  const { currentUser } = useCurrentUser();
  const { billableItems, refetch } = useBillableItems(
    currentUser?.placeBasedCareProvId ?? ""
  );
  const { barbers } = useBarbers(currentUser?.placeBasedCareProvId ?? "");
  const columnHeaders: ColDef[] = useMemo(
    () => [
      {
        headerName: "Date",
        field: "serviceDate",
        valueFormatter: (v) =>
          DateTime.fromJSDate(v.data.serviceDate).toFormat("MM/dd/yy"),
        flex: 0.2
      },
      {
        field: "description",
        flex: 1
      },
      {
        field: "barber",
        valueFormatter: (v) => {
          return (
            barbers?.find((b) => b.uid === v.data.barberId)?.name.display ?? ""
          );
        }
      },
      {
        field: "amount",
        valueFormatter: (v) => "$" + (v.data.amount / 100).toFixed(2),
        flex: 0.2
      },
      {
        headerName: "Actions",
        field: "actions",
        cellRenderer: (params: any) => (
          <BillableItemActions
            {...params}
            onActionComplete={refetch}
            transfersEnabled={
              barbers?.find((b) => b.uid === params.data.barberId)
                ?.stripeStatus === StripeStatus.TransfersEnabled
            }
          />
        ),
        sortable: false,
        filter: false,
        flex: 0.25
      }
    ],
    [refetch, barbers]
  );
  const parsedBillableItemData = useMemo(() => {
    // only show billableItems that are New and unsubmitted or not denied
    return billableItems.filter(
      (bi) =>
        bi.stripePmtStatusChanges.length === 0 &&
        !bi.billStatusChanges.some((bsc) => bsc.status === BillStatus.Denied)
    );
  }, [billableItems]);
  if (currentUser?.userType !== UserType.ProgramManager) {
    return (
      <Center h={"full"}>
        <Text>Only Program Managers may view this screen</Text>
      </Center>
    );
  }
  return (
    <VStack p={2} h={"full"} w={"full"}>
      <div
        className='ag-theme-quartz'
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          rowData={parsedBillableItemData}
          columnDefs={columnHeaders as any}
        />
      </div>
    </VStack>
  );
};

export default PaymentsTable;

const BillableItemActions = ({
  onActionComplete,
  transfersEnabled,
  ...params
}: any) => {
  const { data } = params;
  const { currentUser } = useCurrentUser();
  const [action, setAction] = useState<"Approved" | "Denied">();
  const [loading, setLoading] = useState(false);
  const [denialReason, setDenialReason] = useState("");
  const cancelRef = useRef(null);
  const { onClose, isOpen, onOpen } = useDisclosure();
  // const { barber } = useBarber(data.barberId);
  // const barberCanAcceptPayments =
  //   barber?.stripeStatus === StripeStatus.TransfersEnabled;

  const updateBillableItemApproval = async (status: "Approved" | "Denied") => {
    if (!currentUser || !transfersEnabled) return;
    setLoading(true);
    const billableItemService = new BillableItemService();
    const billableItem = new BillableItem(data);
    if (status === "Denied") {
      billableItem.billStatusChanges.push(
        new BillStatusChange({
          status: BillStatus.Denied,
          date: new Date(),
          editorId: currentUser.uid,
          editorType: currentUser.userType,
          details: `Denied by ${currentUser?.name.display}${
            denialReason ? " : " + denialReason : ""
          }`
        })
      );
      await billableItemService.updateBillableItem(billableItem);
    } else {
      await AppointmentTransactionService.approveBillableItem({
        billableItemId: billableItem.modelId,
        editorId: currentUser.uid,
        billStatusUpdate: BillStatus.ApprovedForSubmission
      });
    }
    setLoading(false);
    onClose();
    onActionComplete();
  };
  const dialogHeader = useMemo(
    () =>
      action === "Approved"
        ? "Approve Payment Request"
        : action === "Denied"
        ? "Deny Payment Request"
        : "",
    [action]
  );
  const dialogBody = useMemo(
    () =>
      action === "Approved"
        ? `Approve payment of $${(data.amount / 100).toFixed(2)}.`
        : action === "Denied"
        ? `Deny payment of $${(data.amount / 100).toFixed(2)}.`
        : "",
    [action, data]
  );
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {dialogHeader}
            </AlertDialogHeader>

            <AlertDialogBody display={"flex"} flexDir={"column"}>
              <Text fontWeight={"normal"}>
                {dialogBody} You can't undo this action afterwards.
              </Text>
              {action === "Denied" ? (
                <FormControl>
                  <FormLabel>Denial Reason (optional)</FormLabel>
                  <Input
                    placeholder={"Why is this request being denied?"}
                    value={denialReason}
                    onChange={(e) => setDenialReason(e.target.value)}
                  />
                </FormControl>
              ) : (
                <></>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme='blue'
                onClick={() => action && updateBillableItemApproval(action)}
                ml={3}
              >
                {!loading ? "Confirm" : <Spinner />}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <HStack alignItems={"center"} h={"full"}>
        <Tooltip
          label={
            transfersEnabled
              ? "Approve Payment Request"
              : "Barber cannot accept payments"
          }
        >
          <Button
            onClick={() => {
              setAction("Approved");
              onOpen();
            }}
            size='sm'
            colorScheme='blue'
            isDisabled={!transfersEnabled}
          >
            Approve
          </Button>
        </Tooltip>
        <Tooltip label={"Deny Payment Request"}>
          <Button
            onClick={() => {
              setAction("Denied");
              onOpen();
            }}
            size='sm'
            colorScheme='red'
          >
            Deny
          </Button>
        </Tooltip>
      </HStack>
    </>
  );
};
