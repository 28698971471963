import { Appointment } from "@oben-core-web/models/appointment";
import { AppointmentService } from "@oben-core-web/services/appointment-service";
import { convertToLocalTZ } from "@/lib/timezone";
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { DocumentData, QuerySnapshot } from "firebase/firestore";
import isEqual from "lodash/isEqual";
import { AppointmentStatus } from "@oben-core-web/models/appointment-status-change";

// TODO: fix this to accept a csv string of pharmacistIDs -- we are running too many queries when passing an array
const useAppointments = (queryOptions: {
  pharmacistId?: string | string[];
  barberId?: string;
  start: Date;
  end: Date;
}) => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const prevQueryOptionsRef = useRef(queryOptions);
  const memoizedQueryOptions = useMemo(
    () => ({
      pharmacistId: queryOptions.pharmacistId
        ? Array.isArray(queryOptions.pharmacistId)
          ? queryOptions.pharmacistId
          : queryOptions.pharmacistId?.includes(",")
          ? queryOptions.pharmacistId.split(",")
          : [queryOptions.pharmacistId]
        : undefined,
      barberId: queryOptions.barberId,
      start: queryOptions.start,
      end: queryOptions.end
    }),
    [
      queryOptions.pharmacistId,
      queryOptions.barberId,
      queryOptions.start,
      queryOptions.end
    ]
  );
  const handleSubscription = useCallback(
    (querySnapshot: QuerySnapshot<DocumentData>) => {
      const appts: Appointment[] = [];
      querySnapshot.forEach((docSnap) => {
        if (docSnap.exists()) {
          const data = Appointment.fromFirestore(docSnap);
          appts.push(
            new Appointment({ ...data, date: convertToLocalTZ(data.date!) })
          );
        }
      });
      setAppointments(
        appts.filter(
          (a) =>
            !a.statusChanges.some(
              (s) =>
                s.status === AppointmentStatus.Canceled ||
                s.status === AppointmentStatus.NoShow ||
                s.status === AppointmentStatus.LateCanceled
            )
        )
      );
    },
    []
  );
  // useEffect(() => {
  //   let unsubscribeFunc: (() => void) | undefined;
  //   console.log("effect fun");
  //   if (!isEqual(prevQueryOptionsRef.current, memoizedQueryOptions)) {
  //     console.log("WHY?!?!");
  //     prevQueryOptionsRef.current = memoizedQueryOptions;
  //     if (memoizedQueryOptions.pharmacistId) {
  //       const appointmentService = new AppointmentService();
  //       const { unsubscribe } = appointmentService.streamAppointments({
  //         pharmacistIds: memoizedQueryOptions.pharmacistId,
  //         barberId: memoizedQueryOptions.barberId ?? null,
  //         start: memoizedQueryOptions.start,
  //         end: memoizedQueryOptions.end,
  //         handleSnapshot: handleSubscription
  //       });
  //       unsubscribeFunc = unsubscribe;
  //     }
  //   }

  //   return () => {
  //     console.log("unmount");
  //     if (
  //       unsubscribeFunc &&
  //       !isEqual(prevQueryOptionsRef.current, memoizedQueryOptions)
  //     ) {
  //       console.log("actual unmount");
  //       unsubscribeFunc();
  //     }
  //   };
  // }, [handleSubscription, memoizedQueryOptions]);

  useEffect(() => {
    let unsubscribeFunc: (() => void) | undefined;
    if (!isEqual(prevQueryOptionsRef.current, memoizedQueryOptions)) {
      prevQueryOptionsRef.current = memoizedQueryOptions;
      if (
        (Array.isArray(memoizedQueryOptions.pharmacistId) &&
          memoizedQueryOptions.pharmacistId.length > 0) ||
        (typeof memoizedQueryOptions.pharmacistId === "string" &&
          !!memoizedQueryOptions.pharmacistId) ||
        !!memoizedQueryOptions.barberId
      ) {
        const appointmentService = new AppointmentService();
        const { unsubscribe } = appointmentService.streamAppointments({
          pharmacistIds: memoizedQueryOptions.pharmacistId
            ? Array.isArray(memoizedQueryOptions.pharmacistId)
              ? memoizedQueryOptions.pharmacistId
              : [memoizedQueryOptions.pharmacistId]
            : null,
          barberId: memoizedQueryOptions.barberId ?? null,
          start: memoizedQueryOptions.start,
          end: memoizedQueryOptions.end,
          handleSnapshot: handleSubscription
        });
        unsubscribeFunc = unsubscribe;
      }
    }

    return () => {
      if (
        unsubscribeFunc &&
        !isEqual(prevQueryOptionsRef.current, memoizedQueryOptions)
      ) {
        unsubscribeFunc();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubscription, memoizedQueryOptions]);

  return { appointments };
};

export default useAppointments;
