import useCurrentUser from "@/hooks/useCurrentUser";
import usePatients from "@/hooks/usePatients";
import {
  Card,
  HStack,
  VStack,
  Text,
  SimpleGrid,
  GridItem
} from "@chakra-ui/react";
import DashboardStat from "../atoms/DashboardStat";
import PieChart from "../atoms/SexPieChart";
import { groupBy } from "lodash";
import AgeBarChart from "../atoms/AgeBarChart";
import { DateTime } from "luxon";
import usePharmacistQueries from "@/hooks/usePharmacistQueries";
import { LuUsers } from "react-icons/lu";
import RaceBarChart from "../atoms/RaceBarChart";

const ClientDashboardStatistics = () => {
  const { currentUser } = useCurrentUser();
  const { clientUsers } = usePatients(currentUser?.placeBasedCareProvId ?? "");
  const clientUsersBySex = groupBy(clientUsers, "sex");
  const { countOfPatientsAtBPGoal } = usePharmacistQueries(
    currentUser?.placeBasedCareProvId ?? ""
  );
  return (
    <HStack w={"full"} spacing={4}>
      <VStack w={"30%"} spacing={4} h={"full"}>
        <Card
          p={4}
          w={"full"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"flex-start"}
        >
          <HStack
            h={"fit-content"}
            mt={1}
            border={"1px solid gray"}
            p={1.5}
            rounded={"xl"}
          >
            <LuUsers size={"1.5em"} />
          </HStack>
          <VStack alignItems={"flex-start"} ml={2} w={"45%"}>
            <Text>Total number of patients enrolled</Text>
            <Text fontSize={"x-large"}>
              {clientUsers.filter((c) => !!c.enrollmentDate).length}
            </Text>
          </VStack>
        </Card>
        <DashboardStat
          label={"Patients at BP Goal"}
          value={(countOfPatientsAtBPGoal / clientUsers.length) * 100 + "%"}
          description=''
          w={"full"}
        />
      </VStack>
      <HStack w={"70%"} spacing={4} alignItems={"flex-start"} h={"full"}>
        <SimpleGrid columns={13} gap={4}>
          <GridItem colSpan={3}>
            <PieChart
              title={"Sex Distribution"}
              labels={Object.keys(clientUsersBySex)}
              series={Object.values(clientUsersBySex).map((set) => set.length)}
            />
          </GridItem>
          <GridItem colSpan={5}>
            <AgeBarChart
              ages={clientUsers
                .filter((c) => !!c.dob)
                .map((client) => {
                  return Math.floor(
                    -DateTime.fromJSDate(new Date(client.dob!)).diffNow("years")
                      .years
                  );
                })}
            />
          </GridItem>
          <GridItem colSpan={5}>
            <RaceBarChart
              races={clientUsers
                .filter((c) => !!c.race)
                .map((client) => client.race)}
            />
          </GridItem>
        </SimpleGrid>
      </HStack>
    </HStack>
  );
};

export default ClientDashboardStatistics;
