import { BarberUser } from "@oben-core-web/models/barber-user";
import { Barbershop } from "@oben-core-web/models/barbershop";
import {
  // InternalTask,
  InternalTaskType
} from "@oben-core-web/models/internal-task";
import { BarberUserService } from "@oben-core-web/services/barber-user-service";
import { BarbershopService } from "@oben-core-web/services/barbershop-service";
import { ClientUserService } from "@oben-core-web/services/client-user-service";
import { InternalTaskService } from "@oben-core-web/services/internal-task-service";
import { useCallback, useEffect, useState } from "react";

const useInternalSchedulingTasks = (assigneeId: string) => {
  const [internalTasks, setInternalTasks] = useState<any[]>([]);

  const fetchInternalSchedulingTasks = useCallback(
    async (assigneeId: string) => {
      const internalTaskService = new InternalTaskService();
      const tasks = await internalTaskService.getAssigneeTasks(assigneeId);
      const clientUserService = new ClientUserService();
      const barberUserService = new BarberUserService();
      const barbershopService = new BarbershopService();

      const parsed = await Promise.all(
        tasks
          .filter(
            (t) =>
              t.internalTaskType === InternalTaskType.ScheduleAppointment &&
              !t.completionDate
          )
          .map(async (t) => {
            const client = await clientUserService.getClientUser(t.clientId);
            let prefBarber: BarberUser | null = null;
            let prefBarbershop: Barbershop | null = null;
            if (!client) return t;
            if (client.prefBarberid) {
              prefBarber = await barberUserService.getBarberUser(
                client.prefBarberid
              );
            }
            if (client.prefBarbershopId) {
              prefBarbershop = await barbershopService.getBarbershop(
                client.prefBarbershopId
              );
            }
            return {
              ...t,
              client,
              internalTask: t,
              patientName: client?.name.display ?? "",
              prefApptTimes: client.prefApptTimes.join("; "),
              prefBarber,
              prefBarbershop
            };
          })
      );
      setInternalTasks(parsed);
    },
    []
  );

  useEffect(() => {
    if (assigneeId) {
      fetchInternalSchedulingTasks(assigneeId);
    }
  }, [assigneeId, fetchInternalSchedulingTasks]);

  return { internalTasks, fetchInternalSchedulingTasks };
};

export default useInternalSchedulingTasks;
