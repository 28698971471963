import { DocumentSnapshot } from 'firebase/firestore';

import { PayerService } from '../services/payer-service';
import { Address } from './address';

export interface IPayerData {
  // common fields for all "entities"
  id: string;
  address: Address;
  businessName: string;
  enabled: boolean;
  // payer specific fields
  isMedicaidMCO: boolean;
  fundsNonMedicaidCHWServices: boolean;
}

export class Payer {
  id: string;
  address: Address;
  businessName: string;
  enabled: boolean;
  isMedicaidMCO: boolean;
  fundsNonMedicaidCHWServices: boolean;
  
  constructor({
    id,
    address,
    businessName,
    enabled,
    isMedicaidMCO,
    fundsNonMedicaidCHWServices,
  }: IPayerData) {
    this.id = id;
    this.address = address;
    this.businessName = businessName;
    this.enabled = enabled;
    this.isMedicaidMCO = isMedicaidMCO;
    this.fundsNonMedicaidCHWServices = fundsNonMedicaidCHWServices;
  }

  async updateDb(): Promise<void> {
    const db = new PayerService();
    await db.updatePayer(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): Payer {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return Payer.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): Payer {
    return new Payer({
      id: id,
      address: Address.fromMap(data["address"] ?? {}),
      businessName: data["businessName"] ?? "",
      enabled: data["enabled"] ?? false,
      isMedicaidMCO: data["isMedicaidMCO"] ?? false,
      fundsNonMedicaidCHWServices: data["fundsNonMedicaidCHWServices"] ?? false,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      "address": this.address.toJson(),
      "businessName": this.businessName,
      "enabled": this.enabled,
      "isMedicaidMCO": this.isMedicaidMCO,
      "fundsNonMedicaidCHWServices": this.fundsNonMedicaidCHWServices,
    };
  }
}